
export default {
  name: "MobileTopBar",
  data() {
    return {};
  },
  computed: {
    isOpen: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
