
export default {
  name: "Input",
  props: {
    field: Object,
    errors: Object,
    options: Array,
    textarea: Object,
  },
  computed: {
    data: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
