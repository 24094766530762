import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _70493d71 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _d59e510e = () => interopDefault(import('../pages/demo.vue' /* webpackChunkName: "pages/demo" */))
const _c223e358 = () => interopDefault(import('../pages/design.vue' /* webpackChunkName: "pages/design" */))
const _60668d9f = () => interopDefault(import('../pages/social-login.vue' /* webpackChunkName: "pages/social-login" */))
const _7166f81c = () => interopDefault(import('../pages/terms-privacy.vue' /* webpackChunkName: "pages/terms-privacy" */))
const _dd292050 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _2f470f8e = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _315bba62 = () => interopDefault(import('../pages/dashboard/block-list.vue' /* webpackChunkName: "pages/dashboard/block-list" */))
const _6337c5df = () => interopDefault(import('../pages/dashboard/employee.vue' /* webpackChunkName: "pages/dashboard/employee" */))
const _edd80426 = () => interopDefault(import('../pages/dashboard/order.vue' /* webpackChunkName: "pages/dashboard/order" */))
const _007da48f = () => interopDefault(import('../pages/dashboard/performance.vue' /* webpackChunkName: "pages/dashboard/performance" */))
const _3bd6d8b6 = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _697dc8ae = () => interopDefault(import('../pages/dashboard/restaurant.vue' /* webpackChunkName: "pages/dashboard/restaurant" */))
const _2bd44e58 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _d7ce1764 = () => interopDefault(import('../pages/dashboard/subscription.vue' /* webpackChunkName: "pages/dashboard/subscription" */))
const _29e4726d = () => interopDefault(import('../pages/dashboard/table.vue' /* webpackChunkName: "pages/dashboard/table" */))
const _78a4ae08 = () => interopDefault(import('../pages/dashboard/user.vue' /* webpackChunkName: "pages/dashboard/user" */))
const _49b823f0 = () => interopDefault(import('../pages/dashboard/vat.vue' /* webpackChunkName: "pages/dashboard/vat" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5f43ff34 = () => interopDefault(import('../pages/payment/verify/_method/_code.vue' /* webpackChunkName: "pages/payment/verify/_method/_code" */))
const _f1f7461a = () => interopDefault(import('../pages/menu/_slug/index.vue' /* webpackChunkName: "pages/menu/_slug/index" */))
const _6059687e = () => interopDefault(import('../pages/menu/_slug/_table.vue' /* webpackChunkName: "pages/menu/_slug/_table" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _70493d71,
    name: "dashboard"
  }, {
    path: "/demo",
    component: _d59e510e,
    name: "demo"
  }, {
    path: "/design",
    component: _c223e358,
    name: "design"
  }, {
    path: "/social-login",
    component: _60668d9f,
    name: "social-login"
  }, {
    path: "/terms-privacy",
    component: _7166f81c,
    name: "terms-privacy"
  }, {
    path: "/auth/login",
    component: _dd292050,
    name: "auth-login"
  }, {
    path: "/auth/signup",
    component: _2f470f8e,
    name: "auth-signup"
  }, {
    path: "/dashboard/block-list",
    component: _315bba62,
    name: "dashboard-block-list"
  }, {
    path: "/dashboard/employee",
    component: _6337c5df,
    name: "dashboard-employee"
  }, {
    path: "/dashboard/order",
    component: _edd80426,
    name: "dashboard-order"
  }, {
    path: "/dashboard/performance",
    component: _007da48f,
    name: "dashboard-performance"
  }, {
    path: "/dashboard/report",
    component: _3bd6d8b6,
    name: "dashboard-report"
  }, {
    path: "/dashboard/restaurant",
    component: _697dc8ae,
    name: "dashboard-restaurant"
  }, {
    path: "/dashboard/settings",
    component: _2bd44e58,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/subscription",
    component: _d7ce1764,
    name: "dashboard-subscription"
  }, {
    path: "/dashboard/table",
    component: _29e4726d,
    name: "dashboard-table"
  }, {
    path: "/dashboard/user",
    component: _78a4ae08,
    name: "dashboard-user"
  }, {
    path: "/dashboard/vat",
    component: _49b823f0,
    name: "dashboard-vat"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }, {
    path: "/payment/verify/:method?/:code?",
    component: _5f43ff34,
    name: "payment-verify-method-code"
  }, {
    path: "/menu/:slug",
    component: _f1f7461a,
    name: "menu-slug"
  }, {
    path: "/menu/:slug?/:table",
    component: _6059687e,
    name: "menu-slug-table"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
