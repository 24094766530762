
import socket from "@/utils/socket";
import { mapGetters } from "vuex";

export default {
  name: "RealtimePrintItemList",
  data() {
    return {
      orderDetails: {},
    };
  },
  computed: {
    ...mapGetters(["baseUrl", "manager"]),
  },
  mounted() {
    if (this.$auth?.user?.restaurant?._id && this.manager) {
      socket.on(
        `print-item-list-${this.$auth.user?.restaurant?._id}`,
        (orderID) => {
          this.printItem(orderID);
        }
      );
    }
  },
  beforeDestroy() {
    if (this.$auth?.user?.restaurant?._id && this.manager) {
      socket.off(`print-item-list-${this.$auth?.user?.restaurant?._id}`);
    }
  },
  methods: {
    async printItem(orderID) {
      try {
        const { order } = await this.$managerApi.fetchPrintOrder(orderID);
        this.orderDetails = order;
        setTimeout(() => {
          this.printReceipt();
        }, 500);
      } catch (error) {}
    },
    printReceipt() {
      try {
        this.$nextTick(() => {
          const printContent = this.$refs?.itemList?.innerHTML;
          if (printContent) {
            const printWindow = window.open("", "_blank");
            printWindow.document.write(
              `<html><body style="padding: 0; margin: 0 20px 0 0; font-family: monospace">${printContent.toString()}</body></html>`
            );
            printWindow.document.close();
            printWindow.print();
            printWindow.onafterprint = () => {
              printWindow.close();
            };
          }
        });
      } catch (error) {}
    },
  },
};
