
import { refreshTokens } from "@/utils";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      modal: false,
      loading: false,
      errors: {},
      form: { name: "" },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    inputFields() {
      return [
        {
          type: "text",
          placeholder: "Name",
          name: "name",
        },
      ];
    },
  },
  mounted() {
    this.modal = this.user ? true : false;
  },
  methods: {
    async createRestaurant() {
      try {
        this.loading = true;
        this.errors = {};
        await this.$userApi.createRestaurant(this.form);
        await refreshTokens.call(this);
        this.$nuxt.$emit("success", "Restaurant created successfully");
        this.modal = false;
      } catch (error) {
        this.errors = error?.response?.data?.errors;
      } finally {
        this.loading = false;
      }
    },
  },
};
