
export default {
  name: "NotFound",
  data() {
    return {
      menuRoute: true,
    };
  },
  async mounted() {
    if (process.client) {
      await this.loadLottieScript();
      this.initializeAnimation();
    }
    this.setRoute(this.$route.name === "menu-slug-table");
  },
  methods: {
    async loadLottieScript() {
      return new Promise((resolve, reject) => {
        if (window.lottie) {
          resolve();
        } else {
          const script = document.createElement("script");
          script.src = "/js/lottie.min.js";
          script.onload = resolve;
          script.onerror = reject;
          document.body.appendChild(script);
        }
      });
    },
    initializeAnimation() {
      window.lottie.loadAnimation({
        container: this.$refs.error,
        renderer: "canvas",
        loop: true,
        autoplay: true,
        path: "/lottie/404.json",
      });
    },
    goBack() {
      window.history.back();
    },
    setRoute(val) {
      this.menuRoute = val;
    },
  },
};
