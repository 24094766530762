
import vClickOutside from "v-click-outside";
import { mapGetters } from "vuex";
export default {
  name: "TopBar",
  directives: { clickOutside: vClickOutside.directive },
  data() {
    return {
      dropdownOpen: false,
      currentDate: new Date(),
      timerInterval: null,
    };
  },
  computed: {
    ...mapGetters(["manager", "waiter", "scanDate", "activeSubscription"]),

    // showScanDate() {
    //   const timeDifference = this.scanDate - this.currentDate;

    //   if (timeDifference > 0) {
    //     const seconds = Math.floor(timeDifference / 1000);
    //     const days = Math.floor(seconds / 86400);
    //     const hours = Math.floor((seconds % 86400) / 3600);
    //     const minutes = Math.floor((seconds % 3600) / 60);
    //     const remainingSeconds = seconds % 60;

    //     return `${days}:${this.formatTime(hours)}:${this.formatTime(
    //       minutes
    //     )}:${this.formatTime(remainingSeconds)}`;
    //   } else {
    //     // Display negative countdown
    //     const positiveTimeDifference = Math.abs(timeDifference);
    //     const seconds = Math.floor(positiveTimeDifference / 1000);
    //     const days = Math.floor(seconds / 86400);
    //     const hours = Math.floor((seconds % 86400) / 3600);
    //     const minutes = Math.floor((seconds % 3600) / 60);
    //     const remainingSeconds = seconds % 60;

    //     return `-${days}:${this.formatTime(hours)}:${this.formatTime(
    //       minutes
    //     )}:${this.formatTime(remainingSeconds)}`;
    //   }
    // },
  },
  watch: {
    $route(val) {
      this.dropdownOpen = false;
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods: {
    formatTime(time) {
      return time < 10 ? `0${time}` : time;
    },
    updateCurrentDate() {
      this.currentDate = new Date();
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.updateCurrentDate();
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timerInterval);
    },
    async logOut() {
      try {
        await this.$auth.logout("laravelJWT");
        this.$router.push({ name: "auth-login" });
      } catch (error) {}
    },
  },
};
