
export default {
  name: "Modal",
  props: {
    size: { type: String, default: "lg" },
  },
  computed: {
    modal: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getWidth() {
      if (this.size === "lg") {
        return "lg:w-2/4";
      } else if (this.size === "sm") {
        return "lg:w-1/3";
      }
    },
  },
};
