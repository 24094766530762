const middleware = {}

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['adminOrManager'] = require('../middleware/adminOrManager.js')
middleware['adminOrManager'] = middleware['adminOrManager'].default || middleware['adminOrManager']

middleware['adminOrManagerOrWaiter'] = require('../middleware/adminOrManagerOrWaiter.js')
middleware['adminOrManagerOrWaiter'] = middleware['adminOrManagerOrWaiter'].default || middleware['adminOrManagerOrWaiter']

middleware['adminOrWaiter'] = require('../middleware/adminOrWaiter.js')
middleware['adminOrWaiter'] = middleware['adminOrWaiter'].default || middleware['adminOrWaiter']

middleware['manager'] = require('../middleware/manager.js')
middleware['manager'] = middleware['manager'].default || middleware['manager']

middleware['managerOrUser'] = require('../middleware/managerOrUser.js')
middleware['managerOrUser'] = middleware['managerOrUser'].default || middleware['managerOrUser']

middleware['managerOrWaiter'] = require('../middleware/managerOrWaiter.js')
middleware['managerOrWaiter'] = middleware['managerOrWaiter'].default || middleware['managerOrWaiter']

middleware['waiter'] = require('../middleware/waiter.js')
middleware['waiter'] = middleware['waiter'].default || middleware['waiter']

export default middleware
