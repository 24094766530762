import { render, staticRenderFns } from "./Topbar.vue?vue&type=template&id=02ecc038"
import script from "./Topbar.vue?vue&type=script&lang=js"
export * from "./Topbar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DashboardTopbarNotification: require('/vercel/path0/components/Dashboard/TopbarNotification.vue').default,Button: require('/vercel/path0/components/Button.vue').default})
