export const Badge = () => import('../../components/Badge.vue' /* webpackChunkName: "components/badge" */).then(c => wrapFunctional(c.default || c))
export const Block = () => import('../../components/Block.vue' /* webpackChunkName: "components/block" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const ChartLoading = () => import('../../components/ChartLoading.vue' /* webpackChunkName: "components/chart-loading" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const CloseButton = () => import('../../components/CloseButton.vue' /* webpackChunkName: "components/close-button" */).then(c => wrapFunctional(c.default || c))
export const EditButton = () => import('../../components/EditButton.vue' /* webpackChunkName: "components/edit-button" */).then(c => wrapFunctional(c.default || c))
export const EmptyMessage = () => import('../../components/EmptyMessage.vue' /* webpackChunkName: "components/empty-message" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../components/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const KeyTrigger = () => import('../../components/KeyTrigger.vue' /* webpackChunkName: "components/key-trigger" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Observer = () => import('../../components/Observer.vue' /* webpackChunkName: "components/observer" */).then(c => wrapFunctional(c.default || c))
export const OrderLoading = () => import('../../components/OrderLoading.vue' /* webpackChunkName: "components/order-loading" */).then(c => wrapFunctional(c.default || c))
export const Skeleton = () => import('../../components/Skeleton.vue' /* webpackChunkName: "components/skeleton" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../components/Spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const TabTitle = () => import('../../components/TabTitle.vue' /* webpackChunkName: "components/tab-title" */).then(c => wrapFunctional(c.default || c))
export const TableResponsive = () => import('../../components/TableResponsive.vue' /* webpackChunkName: "components/table-responsive" */).then(c => wrapFunctional(c.default || c))
export const ToggleSwitch = () => import('../../components/ToggleSwitch.vue' /* webpackChunkName: "components/toggle-switch" */).then(c => wrapFunctional(c.default || c))
export const Upgrade = () => import('../../components/Upgrade.vue' /* webpackChunkName: "components/upgrade" */).then(c => wrapFunctional(c.default || c))
export const CreateRestaurant = () => import('../../components/CreateRestaurant/index.vue' /* webpackChunkName: "components/create-restaurant" */).then(c => wrapFunctional(c.default || c))
export const DashboardCreateButton = () => import('../../components/Dashboard/CreateButton.vue' /* webpackChunkName: "components/dashboard-create-button" */).then(c => wrapFunctional(c.default || c))
export const DashboardManager = () => import('../../components/Dashboard/Manager.vue' /* webpackChunkName: "components/dashboard-manager" */).then(c => wrapFunctional(c.default || c))
export const DashboardMobileTopbar = () => import('../../components/Dashboard/MobileTopbar.vue' /* webpackChunkName: "components/dashboard-mobile-topbar" */).then(c => wrapFunctional(c.default || c))
export const DashboardMonthlySalesChart = () => import('../../components/Dashboard/MonthlySalesChart.vue' /* webpackChunkName: "components/dashboard-monthly-sales-chart" */).then(c => wrapFunctional(c.default || c))
export const DashboardRecentOrder = () => import('../../components/Dashboard/RecentOrder.vue' /* webpackChunkName: "components/dashboard-recent-order" */).then(c => wrapFunctional(c.default || c))
export const DashboardRestaurantDetails = () => import('../../components/Dashboard/RestaurantDetails.vue' /* webpackChunkName: "components/dashboard-restaurant-details" */).then(c => wrapFunctional(c.default || c))
export const DashboardSidebar = () => import('../../components/Dashboard/Sidebar.vue' /* webpackChunkName: "components/dashboard-sidebar" */).then(c => wrapFunctional(c.default || c))
export const DashboardSummery = () => import('../../components/Dashboard/Summery.vue' /* webpackChunkName: "components/dashboard-summery" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopbar = () => import('../../components/Dashboard/Topbar.vue' /* webpackChunkName: "components/dashboard-topbar" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopbarNotification = () => import('../../components/Dashboard/TopbarNotification.vue' /* webpackChunkName: "components/dashboard-topbar-notification" */).then(c => wrapFunctional(c.default || c))
export const DashboardWeeklySalesChart = () => import('../../components/Dashboard/WeeklySalesChart.vue' /* webpackChunkName: "components/dashboard-weekly-sales-chart" */).then(c => wrapFunctional(c.default || c))
export const HomeContact = () => import('../../components/Home/Contact.vue' /* webpackChunkName: "components/home-contact" */).then(c => wrapFunctional(c.default || c))
export const HomeCustomer = () => import('../../components/Home/Customer.vue' /* webpackChunkName: "components/home-customer" */).then(c => wrapFunctional(c.default || c))
export const HomeFaq = () => import('../../components/Home/Faq.vue' /* webpackChunkName: "components/home-faq" */).then(c => wrapFunctional(c.default || c))
export const HomeFeature = () => import('../../components/Home/Feature.vue' /* webpackChunkName: "components/home-feature" */).then(c => wrapFunctional(c.default || c))
export const HomeFooter = () => import('../../components/Home/Footer.vue' /* webpackChunkName: "components/home-footer" */).then(c => wrapFunctional(c.default || c))
export const HomeHeader = () => import('../../components/Home/Header.vue' /* webpackChunkName: "components/home-header" */).then(c => wrapFunctional(c.default || c))
export const HomeNav = () => import('../../components/Home/Nav.vue' /* webpackChunkName: "components/home-nav" */).then(c => wrapFunctional(c.default || c))
export const HomePricing = () => import('../../components/Home/Pricing.vue' /* webpackChunkName: "components/home-pricing" */).then(c => wrapFunctional(c.default || c))
export const HomeProcess = () => import('../../components/Home/Process.vue' /* webpackChunkName: "components/home-process" */).then(c => wrapFunctional(c.default || c))
export const ImageModalDelete = () => import('../../components/ImageModal/Delete.vue' /* webpackChunkName: "components/image-modal-delete" */).then(c => wrapFunctional(c.default || c))
export const ImageModalPreset = () => import('../../components/ImageModal/Preset.vue' /* webpackChunkName: "components/image-modal-preset" */).then(c => wrapFunctional(c.default || c))
export const ImageModalSelect = () => import('../../components/ImageModal/Select.vue' /* webpackChunkName: "components/image-modal-select" */).then(c => wrapFunctional(c.default || c))
export const ImageModalUpload = () => import('../../components/ImageModal/Upload.vue' /* webpackChunkName: "components/image-modal-upload" */).then(c => wrapFunctional(c.default || c))
export const ImageModal = () => import('../../components/ImageModal/index.vue' /* webpackChunkName: "components/image-modal" */).then(c => wrapFunctional(c.default || c))
export const MenuCart = () => import('../../components/Menu/Cart.vue' /* webpackChunkName: "components/menu-cart" */).then(c => wrapFunctional(c.default || c))
export const MenuCategory = () => import('../../components/Menu/Category.vue' /* webpackChunkName: "components/menu-category" */).then(c => wrapFunctional(c.default || c))
export const MenuModal = () => import('../../components/Menu/Modal.vue' /* webpackChunkName: "components/menu-modal" */).then(c => wrapFunctional(c.default || c))
export const MenuRestaurantName = () => import('../../components/Menu/RestaurantName.vue' /* webpackChunkName: "components/menu-restaurant-name" */).then(c => wrapFunctional(c.default || c))
export const MenuSubCategory = () => import('../../components/Menu/SubCategory.vue' /* webpackChunkName: "components/menu-sub-category" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/Menu/index.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const OrderDetails = () => import('../../components/Order/Details.vue' /* webpackChunkName: "components/order-details" */).then(c => wrapFunctional(c.default || c))
export const PrintReceipt = () => import('../../components/Print/Receipt.vue' /* webpackChunkName: "components/print-receipt" */).then(c => wrapFunctional(c.default || c))
export const RealtimeNotification = () => import('../../components/Realtime/Notification.vue' /* webpackChunkName: "components/realtime-notification" */).then(c => wrapFunctional(c.default || c))
export const RealtimeOrderNotification = () => import('../../components/Realtime/OrderNotification.vue' /* webpackChunkName: "components/realtime-order-notification" */).then(c => wrapFunctional(c.default || c))
export const RealtimePrintItemList = () => import('../../components/Realtime/PrintItemList.vue' /* webpackChunkName: "components/realtime-print-item-list" */).then(c => wrapFunctional(c.default || c))
export const ReportDailyBestItem = () => import('../../components/Report/DailyBestItem.vue' /* webpackChunkName: "components/report-daily-best-item" */).then(c => wrapFunctional(c.default || c))
export const ReportEarning = () => import('../../components/Report/Earning.vue' /* webpackChunkName: "components/report-earning" */).then(c => wrapFunctional(c.default || c))
export const ReportMonthlyBestItem = () => import('../../components/Report/MonthlyBestItem.vue' /* webpackChunkName: "components/report-monthly-best-item" */).then(c => wrapFunctional(c.default || c))
export const ReportOrder = () => import('../../components/Report/Order.vue' /* webpackChunkName: "components/report-order" */).then(c => wrapFunctional(c.default || c))
export const ReportWaiterOrders = () => import('../../components/Report/WaiterOrders.vue' /* webpackChunkName: "components/report-waiter-orders" */).then(c => wrapFunctional(c.default || c))
export const ReportWaiterSales = () => import('../../components/Report/WaiterSales.vue' /* webpackChunkName: "components/report-waiter-sales" */).then(c => wrapFunctional(c.default || c))
export const ReportWeeklyBestItem = () => import('../../components/Report/WeeklyBestItem.vue' /* webpackChunkName: "components/report-weekly-best-item" */).then(c => wrapFunctional(c.default || c))
export const SettingsCurrency = () => import('../../components/settings/Currency.vue' /* webpackChunkName: "components/settings-currency" */).then(c => wrapFunctional(c.default || c))
export const SettingsNotification = () => import('../../components/settings/Notification.vue' /* webpackChunkName: "components/settings-notification" */).then(c => wrapFunctional(c.default || c))
export const SettingsPassword = () => import('../../components/settings/Password.vue' /* webpackChunkName: "components/settings-password" */).then(c => wrapFunctional(c.default || c))
export const SettingsPrinting = () => import('../../components/settings/Printing.vue' /* webpackChunkName: "components/settings-printing" */).then(c => wrapFunctional(c.default || c))
export const SettingsProfile = () => import('../../components/settings/Profile.vue' /* webpackChunkName: "components/settings-profile" */).then(c => wrapFunctional(c.default || c))
export const SettingsSetting = () => import('../../components/settings/Setting.vue' /* webpackChunkName: "components/settings-setting" */).then(c => wrapFunctional(c.default || c))
export const MenuItemAddon = () => import('../../components/Menu/Item/Addon.vue' /* webpackChunkName: "components/menu-item-addon" */).then(c => wrapFunctional(c.default || c))
export const MenuItemChoice = () => import('../../components/Menu/Item/Choice.vue' /* webpackChunkName: "components/menu-item-choice" */).then(c => wrapFunctional(c.default || c))
export const MenuItemDish = () => import('../../components/Menu/Item/Dish.vue' /* webpackChunkName: "components/menu-item-dish" */).then(c => wrapFunctional(c.default || c))
export const MenuItemGeneral = () => import('../../components/Menu/Item/General.vue' /* webpackChunkName: "components/menu-item-general" */).then(c => wrapFunctional(c.default || c))
export const MenuItem = () => import('../../components/Menu/Item/index.vue' /* webpackChunkName: "components/menu-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
