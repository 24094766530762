
import vClickOutside from "v-click-outside";
import { mapGetters } from "vuex";
export default {
  name: "TopBarNotification",
  directives: { clickOutside: vClickOutside.directive },
  data() {
    return {
      notification: false,
      loading: false,
      click: true,
      unread: 0,
      perPage: 10,
      items: [],
    };
  },
  computed: {
    ...mapGetters(["manager", "activeSubscription"]),
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    async fetchItems() {
      if (this.click && this.activeSubscription) {
        try {
          this.click = false;
          this.loading = true;
          const params = {
            perPage: this.perPage,
            page: this.items.length / this.perPage + 1,
          };
          if (Number.isInteger(params.page)) {
            const { notification, unread } =
              await this.$managerApi.fetchNotification(params);
            this.unread = unread;
            this.items = this.items.concat(notification);
          }
        } catch (error) {
        } finally {
          this.loading = false;
          this.click = true;
        }
      }
    },
    async markNotification(notification) {
      try {
        if (notification.mark === false) {
          await this.$managerApi.toggleNotification({
            notificationID: notification._id,
          });

          const index = this.items.findIndex(
            ({ _id }) => notification._id === _id
          );
          if (index !== -1) {
            this.items[index].mark = true;
            --this.unread;
          }
        }
        if (
          notification.type === "requestBill" ||
          notification.type === "updateOrder" ||
          notification.type === "cancelOrderRequest" ||
          notification.type === "newOrder"
        ) {
          this.$router.push({
            name: "dashboard-order",
            params: { order_id: notification.additional.orderID },
          });
          this.notification = false;
          setTimeout(
            () => {
              this.$nuxt.$emit(
                "openOrderDetails",
                notification.additional.orderID
              );
            },
            this.$route.name === "dashboard-order" ? 0 : 1000
          );
        }
      } catch (error) {}
    },
    async markAllRead() {
      try {
        await this.$managerApi.markAllNotification();
        this.items = this.items.map((data) => ({ ...data, mark: true }));
        this.unread = 0;
      } catch (error) {}
    },
    async newNotification(notification) {
      try {
        if (notification) {
          this.items = [notification, ...this.items];
          ++this.unread;
        }
      } catch (error) {}
    },
    notificationIcon(title) {
      if (title == "Order Cancel Request") {
        return `<i class="fas fa-xmark text-rose-600"></i>`;
      } else if (title == "Requesting Bill" || title == "Requesting bill") {
        return `<i class="fas fa-file-invoice text-purple-600"></i>`;
      } else if (title == "New Order") {
        return `<i class="far fa-calendar-plus text-green-600"></i>`;
      } else if (title == "Order updated") {
        return `<i class="fas fa-file-invoice-dollar text-amber-600"></i>`;
      }
    },
    async refetch() {
      this.items = [];
      await this.fetchItems();
    },
  },
};
