
import { mapGetters } from "vuex";
export default {
  name: "Sidebar",
  props: { isOpen: Boolean },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["manager", "admin", "waiter", "user"]),
    navLink() {
      const nav = [
        {
          show: this.manager || this.waiter || this.admin,
          name: "dashboard",
          title: "Dashboard",
          icon: "fas fa-cubes",
        },
        {
          show: this.admin,
          name: "dashboard-user",
          title: "User",
          icon: "fas fa-users",
        },
        {
          show: this.manager || this.waiter,
          name: "dashboard-order",
          title: "Order",
          icon: "fas fa-chart-line",
        },
        {
          show: this.manager,
          name: "dashboard-report",
          title: "Report",
          icon: "fas fa-chart-pie",
        },
        {
          show: this.manager,
          name: "dashboard-table",
          title: "Table",
          icon: "fas fa-table-cells-large",
        },
        {
          show: this.manager,
          name: "dashboard-employee",
          title: "Employee",
          icon: "fas fa-users",
        },
        {
          show: this.manager,
          name: "dashboard-vat",
          title: "Vat",
          icon: "fas fa-percent",
        },
        {
          show: this.manager,
          name: "dashboard-block-list",
          title: "Block List",
          icon: "fas fa-users-slash",
        },
        {
          show: this.manager,
          name: "dashboard-restaurant",
          title: "Restaurant",
          icon: "fas fa-utensils",
        },
        {
          show: this.manager || this.admin || this.waiter,
          name: "dashboard-settings",
          title: "Settings",
          icon: "fas fa-gear",
        },
        {
          show: this.manager || this.user,
          name: "dashboard-subscription",
          title: "Subscription",
          icon: "fas fa-address-card",
        },
      ];
      return nav;
    },
  },
  methods: {
    check(name) {
      return this.$route.name === name ? "bg-gray-100" : "";
    },
    async logOut() {
      try {
        await this.$auth.logout("laravelJWT");
        this.$router.push({ name: "auth-login" });
      } catch (error) {}
    },
  },
};
